<div *ngIf="search" class="saved-search-container">
    <div class="saved-search">
        <div class="map-container" (click)="OnClickedMap($event)">
            <label class="map-text">{{mapText}}</label>
            <div *ngIf="search.badgeCount>0" class="map-badge">
                <label>14</label>
                <span>New</span>
            </div>
        </div>

        <div class="search-details">
            <a (click)="OnClickedMap($event)" nav-anchor>
                <h3  class="small" title="{{searchArea}}">{{searchArea}}</h3>
                <p class="search-type" title="{{description}}">{{description}}</p>
            </a>
        
            <div class="actions">
                <switch caption="Alerts" (onClickEvent)="OnClickedAlerts()" [isChecked]="alertsOn"></switch>
                <switch caption="Include nearby properties" (onClickEvent)="OnClickedRecommendations()" [isChecked]="recommendationsOn"></switch>  
            </div>

        </div>

        <div class="delete">
            <a (click)="OnClickedDelete()" title="Delete">
                <pb-icon name="cross"></pb-icon>
            </a>  
        </div>
    </div>

</div>