import { Component, ViewChild, OnDestroy, ElementRef, Input, ComponentFactoryResolver, ViewEncapsulation } from "@angular/core";
import { PlaceSearchCriteria, LocallySavedPlaceSearchCriteria } from "src/app/infrastructure/model/placeSearchCriteria";
import { ShareComponentModel } from "../../component.base.classes/sharing.component.base";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { AdaptiveStateChangedMessageData, YesNoPopupResultMessage, ShowLoginMessageData, ShowYesNoPopupMessageData } from "src/app/infrastructure/message.data.types";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { ShareComponent } from "src/app/modules/isolated.components/share/share.component";
import { CopyObject, DeleteProperties } from "src/app/infrastructure/object.helpers";
import { KeyValuePair } from "src/app/infrastructure/types";
import { IDynamicComponentParameters, DYNAMIC_COMPONENT_TYPES } from "../../component.base.classes/dynamic.component.container.component.base";
import { NgIf } from "@angular/common";
import { SwitchComponent } from "../../../controls/switch/switch.component";
import { AuthorizationService } from "src/app/services/authorization.module/authorization.service";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { PlacebuzzSearchService } from "src/app/services/search.module/placebuzz.search.service";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { UrlHelpersService } from "src/app/services/url.helpers.module/url.helpers.service";
import { PbImageComponent } from "src/app/controls/pb-image/pb-image.component";
import { Router } from "@angular/router";
import { PbIconComponent } from "src/app/controls/pb-icon/pb-icon.component";

@Component({
    selector: 'saved-search',
    templateUrl: './saved-search.component.html',
    styleUrls: ['./saved-search.component.scss'],
    standalone: true,
    imports: [NgIf, SwitchComponent, PbImageComponent, PbIconComponent]
})
export class SavedSearchComponent implements OnDestroy {

    @ViewChild('highlightElement') highlightViewChild: ElementRef;
    private _alertsOn: boolean;
    private _recommendationsOn: boolean;
    private _search: PlaceSearchCriteria;
    private yesNoId: number = Math.random();

    isMobileApp: boolean = false;
    mapText: string;

    shareDynamicComponentParameterers: IDynamicComponentParameters = {
        dynamicComponentResolver: (resolver: ComponentFactoryResolver) => {
            return resolver.resolveComponentFactory(ShareComponent);
        },
        componentParameters: new ShareComponentModel(),
        componentType: DYNAMIC_COMPONENT_TYPES.SHARE
    };

    private adaptiveStateChangedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ADAPTIVE_STATE_CHANGED,
        On: (data: AdaptiveStateChangedMessageData) => {
            this.isMobile = data.isMobile;
        }
    }

    private yesNoPopupResultSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.YES_NO_POP_RESULT,
        On: (data: YesNoPopupResultMessage) => {
            if (data.popupId === this.yesNoId) {
                this.messenger.Unsubscribe(this.yesNoPopupResultSubscriber);
                if (data.isYes) {
                    this.DoDeleteSearch();
                }
            }
        }
    }

    @Input() searchArea: string;
    @Input() description: string;
    @Input() set search(value: PlaceSearchCriteria) {
        this._search = value;
        if (value) {
            this.searchArea = this.search.searchArea;
            this.description = this.search.description;
            this.alertsOn = this.search.hasAlerts;
            this.recommendationsOn = this.search.hasRecommendations;
            this.mapText = (this.search.locationName || this.search.searchArea).split(',')[0];

            this.shareDynamicComponentParameterers.componentParameters.shareContentText = "I thought you might like to try using Placebuzz to search for properties in the UK. Placebuzz offers user-friendly features, including useful search filters and pricing data.";
            this.shareDynamicComponentParameterers.componentParameters.shareSocialMediaText = "Check out the Placebuzz property search tool, with pricing data and useful filters: ";
            this.shareDynamicComponentParameterers.componentParameters.shareSubjectText = "Try the Placebuzz property search tool";
            this.shareDynamicComponentParameterers.componentParameters.subHeader = "";
            this.shareDynamicComponentParameterers.componentParameters.shareUrl = value.shareUrl;
        }
    }
    get search(): PlaceSearchCriteria {
        return this._search;
    }

    @Input() set alertsOn(value: boolean) {
        this._alertsOn = value;
        this.alertsText = "Alerts:" + " " + (this.alertsOn ? "On" : "Off");
        this.turnOnOffText = this.alertsOn ? "Turn Off" : "Turn On";
    }
    get alertsOn(): boolean {
        return this._alertsOn;
    }

    @Input() set recommendationsOn(value: boolean) {
        if (value === undefined || value === null) { value = true; }
        this._recommendationsOn = value;
    }
    get recommendationsOn(): boolean {
        return this._recommendationsOn;
    }

    @Input() hasDelete: boolean;

    alertsText: string;
    turnOnOffText: string;
    isMobile: boolean;

    constructor(
        
        private authorizationService: AuthorizationService,
        private messenger: Messenger,
        private applicationStateService: ApplicationStateService,
        private urlHelpers: UrlHelpersService,
        private router: Router,
        private placebuzzSearchService: PlacebuzzSearchService
    ) {
        this.isMobileApp = this.applicationStateService.isMobileApp;
        this.messenger.Subscribe(this.adaptiveStateChangedSubscriber);
    }

    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.adaptiveStateChangedSubscriber);
    }

    OnClickedAlerts(): void {
        if (!this.ShowSignUpIfLoggedOut()) {
            var searchToSave: PlaceSearchCriteria = JSON.parse(JSON.stringify(this.search));
            searchToSave.hasAlerts = !this.alertsOn;
            if (searchToSave.placeSearchCriteriaId) {
                this.placebuzzSearchService.SetSavedSearchAlerts(searchToSave.placeSearchCriteriaId, searchToSave.hasAlerts, this.OnClickedAlerts, this).then(response => {
                    this.search.hasAlerts = response;
                    this.alertsOn = this.search.hasAlerts;
                    this.UpdateHasAlertsInPath();
                });
            }
            else {
                this.placebuzzSearchService.PerformSaveSearchMutation(searchToSave, this.OnClickedAlerts, this).then(searchCriteriaResponse => {
                    this.search = searchCriteriaResponse;
                    this.alertsOn = searchToSave.hasAlerts;
                    this.UpdateHasAlertsInPath();
                });
            }
        }
    }

    OnClickedRecommendations(): void {
        this.recommendationsOn = !this.recommendationsOn;
        var searchToSave: PlaceSearchCriteria = CopyObject(this.search);
        searchToSave.path = this.urlHelpers.RemoveQueriesFromPath(searchToSave.path, ["HasRecommendations"]);
        if (this.recommendationsOn === false) {
            searchToSave.hasRecommendations = false;
            searchToSave.path = this.urlHelpers.AddQueriesToPath(searchToSave.path, [new KeyValuePair<string, string>("HasRecommendations", "False")]);
        }
        else {
            DeleteProperties(searchToSave, ["hasRecommendations"]);
            DeleteProperties(this.search, ["hasRecommendations"]);
            this.search.path = searchToSave.path;
        }

        this.search.path = searchToSave.path;
        if (!searchToSave.placeSearchCriteriaId) {
            this.placebuzzSearchService.SaveSearchLocally(searchToSave, (this.search as LocallySavedPlaceSearchCriteria).heading);
        }
        else {
            this.placebuzzSearchService.SetSavedSearchRecommendations(searchToSave.placeSearchCriteriaId, this.recommendationsOn, this.OnClickedRecommendations, this).then(response => {
                this.search.hasRecommendations = response;
                if (this.search.hasRecommendations === true) {
                    DeleteProperties(this.search, ["hasRecommendations"]);
                }
                this.placebuzzSearchService.SaveSearchLocally(this.search, (this.search as LocallySavedPlaceSearchCriteria).heading);
            });
        }
    }

    OnClickedDelete() {
        this.ShowYesNoDialog();
    }

    OnClickedMap(e: any) {
       
        this.router.navigateByUrl(this.search.path);
    }

    private DoDeleteSearch() {
        if (this.search.placeSearchCriteriaId) {
            this.placebuzzSearchService.DeleteSavedSearch(this.search).then(() => { });
        }
        else {
            this.placebuzzSearchService.DeleteLocalSearch(this.search);
        }
    }

    // Will return true if showed sign up
    private ShowSignUpIfLoggedOut(): boolean {
        if (!this.authorizationService.isLoggedIn) {
            var loginMessageData: ShowLoginMessageData = {
                showOverlay: true,
                isRegistration: true,
                shouldTurnOnAlerts: true,
                registerText: [
                    "Get new properties straight to your inbox",
                    "Sign up to",
                    "get",
                    "alerts when properties are listed anywhere online",
                ]
            }
            this.messenger.Send({
                messageType: MESSAGE_TYPES.SHOW_LOGIN,
                messageData: loginMessageData
            });
            return true;
        }
        return false;
    }

    private ShowYesNoDialog() {
        var text = "";
        if ((<any>this.search).heading) {
            text = ("Are you sure you want to delete the search " + (<any>this.search).heading) + "?"
        }
        else {
            text = "Are you sure you want to delete your search in " + this.search.searchArea + "?";
        }
        this.messenger.Subscribe(this.yesNoPopupResultSubscriber);
        this.messenger.Send({
            messageType: MESSAGE_TYPES.SHOW_YES_NO_POPUP,
            messageData: new ShowYesNoPopupMessageData({
                width: "636px",
                height: "268px",
                cancelButtonText: "No",
                okButtonText: "Delete",
                questionText: "Delete this search?",
                text: text,
                resultData: {},
                yesNoId: this.yesNoId
            })
        })
    }

    private UpdateHasAlertsInPath() {
        this.search.path = this.urlHelpers.RemoveQueriesFromPath(this.search.path, ["HasAlerts"]);
        if (this.alertsOn) {
            this.search.path = this.urlHelpers.AddQueriesToPath(this.search.path, [new KeyValuePair("HasAlerts", "true")]);
        }
    }
}

