import { ViewChild, ViewContainerRef, ComponentFactoryResolver, Injector, Input, Directive } from "@angular/core";

export const DYNAMIC_COMPONENT_TYPES = {
    LOGIN: "LoginComponent",
    SEARCH_BAR: "SearchBarComponent",
    SELECT_PROPERTY_TYPE: "SelectPropertyTypeComponent",
    FROM_TO_FILTER: "FromToFilterComponent",
    MORE_SEARCH_FILTERS: "MoreSearchFiltersComponent",
    SEARCH_MOBILE: "SearchBarMobileComponent",
    ACCORDION: "AccordionComponent",
    SORT_BY: "SortByComponent",
    RADIUS: "RadiusComponent",
    GEO_SEARCH_INPUT: "GeoSearchInputComponent",
    FOR_SALE_OR_RENT: "ForSaleOrRent",
    AVERAGE_PRICES_WIDGET: "AveragePricesWidget",
    PROPERTY_MAP: "PropertyMap",
    RETINA_IMAGE: "RetinaImage",
    RETINA_IMAGE_EX: "RetinaImageEx",
    // ERROR: "ErrorComponent",
    CONTACT_AGENT: "ContactAgentComponent",
    CLOSE_ACCOUNT: "CloseAccountComponent",
    SHARE: "ShareComponent",
    YES_NO_POPUP: "YesNoPopupComponent",
    SWIPER_SLIDER: "SwiperSliderComponent",
    HLL_DETAILS: "HighlightedListingDetailsPopupComponent",
    TEXT_POPUP: "TextPopupComponent",
    VIDEO_PLAYER: "VideoPlayerComponent",
    I_FRAME: "IFrameComponent"
}


export interface IDynamicComponentParameters {
    componentType: string;
    componentParameters: any;
    dynamicComponentResolver?: Function;
    resolvedComponent?: any;
    setComponentParametersBeforeLoadingAsync?(componentParameters: any): Promise<any>,
    iteratedParameters?: any
}


@Directive()
export class DynamicComponentContainerComponentBase {
    @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true }) dynamicComponentContainer: ViewContainerRef;
    private _dynamicComponentParameters: IDynamicComponentParameters;

    hasLoaded: boolean;
    currentComponent: any;

    constructor(
        protected resolver: ComponentFactoryResolver,
        protected injector: Injector) {

    }

    @Input() set dynamicComponentParameters(value: IDynamicComponentParameters) {
        this._dynamicComponentParameters = value;
        this.LoadDynamicComponent();
    }
    get dynamicComponentParameters(): IDynamicComponentParameters { return this._dynamicComponentParameters; }

    GetContainedComponentInstance(): any {
        return this.currentComponent.instance;
    }

    protected async LoadDynamicComponent() {
        if (this.dynamicComponentContainer && !this.hasLoaded) {
            this.CleanUp();
            if (this.dynamicComponentParameters) {
                let component: any = this.dynamicComponentParameters.resolvedComponent;
                if (!component) {
                    if (this.dynamicComponentParameters.dynamicComponentResolver) {
                        component = this.dynamicComponentParameters.dynamicComponentResolver(this.resolver).create(this.injector);
                    }
                    else {
                        throw new Error("no resolved component was provided!!");
                    }
                }
                this.hasLoaded = true;
                if (this.dynamicComponentParameters.setComponentParametersBeforeLoadingAsync) {
                    this.dynamicComponentParameters.componentParameters = await this.dynamicComponentParameters.setComponentParametersBeforeLoadingAsync(this.dynamicComponentParameters.componentParameters);
                }
                else if (this.dynamicComponentParameters.iteratedParameters) {
                    for (let p in this.dynamicComponentParameters.iteratedParameters) {
                        component.instance[p] = this.dynamicComponentParameters.iteratedParameters[p];
                    }
                }
                this.dynamicComponentContainer.insert(component.hostView);
                this.currentComponent = component;
                this.currentComponent.instance.parameters = this.dynamicComponentParameters.componentParameters;
                this.OnLoadedComponentOverride();
            }
        }
    }

    protected LoadDataIntoResolvedComponent(resolvedComponent: any, data: any) {
        if (this.dynamicComponentContainer) {
            this.CleanUp();
            let component = resolvedComponent;
            this.dynamicComponentContainer.insert(component.hostView);
            this.currentComponent = component;
            this.currentComponent.instance.parameters = data;
            this.OnLoadedComponentOverride();
        }
    }

    protected CleanUp() {
        this.hasLoaded = false;
        this.dynamicComponentContainer.clear();
        if (this.currentComponent) {
            this.currentComponent.destroy();
        }
    }

    protected OnLoadedComponentOverride() {

    }
}